import * as React from "react";
import { Button } from "antd";

const Services = () => {
  return (
    <div className="flex flex-col bg-white md:mt-36 mt-36">
      {/* Header Section */}
      <div className="flex flex-col w-full bg-white">
        <div className="flex flex-col px-36 mt-12 w-full max-md:px-5 max-md:mt-10">
          <div className="flex flex-col py-16 px-4 w-full rounded-3xl bg-custom-light-green">
            <h4 className="px-6 py-2 self-center leading-relaxed rounded-3xl bg-custom-blue text-white max-md:px-5">
              What we do
            </h4>
            <h2 className="mt-6 lg:text-4xl font-bold text-center text-gray-800 text-2xl">
              Delivering Excellence across multiple Domains
            </h2>
          </div>
        </div>
      </div>
      
      {/* Content Rows */}
      <div className="flex flex-col px-36 mt-16 w-full max-md:px-5 max-md:mt-10">
        {/* First Row */}
        <div className="flex flex-col-reverse xl:flex-row gap-8 pb-16 w-full border-b border-solid">
          <div className="flex flex-col justify-between px-12 py-10 bg-gray-100 rounded-3xl flex-1 min-w-[240px]">
            <div className="flex flex-col w-full">
              <h3 className="text-4xl font-bold leading-relaxed text-gray-800 max-md:text-2xl">
                Billing & payment solutions
              </h3>
              <div className="mt-6 leading-7 text-gray-500">
                Emerging digital technologies and savvy customers are prompting utilities to improve billing, customer service, and revenue collection.
                <br />
                <br />
                By utilizing Internet, Mobile, POS, and Agency Banking, utilities can enhance customer experiences. We design, develop, and support integrated billing and customer service systems, recognizing their importance in utility operations.
                <br />
                <br />
                Our platforms connect with banks and payment methods like Mobile Money and Interswitch, while our mobile apps for Android and Windows make billing services easily accessible.
                <br />
                <br />
                We are your tech one-stop-centre for analysis, design, deployment and support to ensure that’s clients enjoy a seamless experience. Our expertise in consulting and application development sets us apart in the industry.
              </div>
            </div>
            <Button className="px-6 mt-10 w-full text-base font-semibold leading-relaxed text-white border-none rounded-lg bg-custom-blue min-h-[48px]">
              View all projects
            </Button>
          </div>
          <div className="flex flex-1 rounded-3xl bg-lime-400 bg-opacity-10 h-[690px] max-md:w-full">
            <img
              src="images/7.svg"
              alt="Stock Cake"
              className="object-cover w-full h-[400px] xl:h-full rounded-3xl"
            />
          </div>
        </div>
        
        {/* Second Row */}
        <div className="flex flex-col-reverse xl:flex-row gap-8 pb-16 mt-16 w-full border-b border-solid">
          <div className="flex flex-1 rounded-3xl bg-lime-400 bg-opacity-10 h-[720px] max-md:w-full">
            <img
              src="images/consultancy.svg"
              alt="Stock Cake"
              className="object-cover w-full h-[400px] xl:h-full  rounded-3xl"
            />
          </div>
          <div className="flex flex-col justify-between px-12 py-10 bg-gray-100 rounded-3xl flex-1 min-w-[240px]">
            <div className="flex flex-col w-full">
              <h3 className="text-4xl font-bold leading-relaxed text-gray-800 max-md:text-2xl">
                I.T Consultancy services
              </h3>
              <div className="mt-6 leading-7 text-gray-500">
                We focus on understanding customer needs and expectations, providing expert advice on leveraging IT to achieve business objectives. Our proven methodologies help improve billing, enhance customer service delivery, optimize costs, reduce risk, and accelerate ROI on IT investments. 
                <br />
                <br />
                We ensure our recommendations align with business processes and strategies, fixing identified gaps with optimal solutions. TraceCorp, offers the best-in-class IT consultancy services, including:
                <br />
                Strategy development and ICT policy formulation and implementation
                <br />
                Infrastructure design, sizing, performance monitoring, disaster recovery, and business continuity services
                <br />
                IT security and audit compliance
                <br />
                Business process and change management
                <br />
                Project management (based on PMP methodology)
                <br />
                Business continuity management
                <br />
                Training on developed business solutions and industry best practices
              </div>
            </div>
            <Button className="px-6 mt-10 w-full text-base font-semibold leading-relaxed border-none text-white rounded-lg bg-custom-blue min-h-[48px]">
              View all projects
            </Button>
          </div>
        </div>

        {/* Third Row */}
        <div className="flex flex-col-reverse xl:flex-row gap-8 pb-16 mt-16 w-full border-b border-solid">
          <div className="flex flex-col justify-between px-12 py-10 bg-gray-100 rounded-3xl flex-1 min-w-[240px]">
            <div className="flex flex-col w-full">
              <h3 className="text-4xl font-bold leading-relaxed text-gray-800 max-md:text-2xl">
                I.T security and audit services
              </h3>
              <div className="mt-6 leading-7 text-gray-500">
                Every business must protect its critical data from internal and external threats. A robust security policy, aligned with best practices and embedded into your systems, is essential. With the rise of mobility, social networking, cloud services, and international hacking, your security infrastructure must be resilient.
                <br />
                <br />
                Through our strong global partnerships, we offer comprehensive security solutions, including:
                <br />
                <br />
                SIEM (Security Information & Event Management) and Data Integrity Solutions
                <br />
                VAPT (Vulnerability Assessment & Penetration Testing)
                <br />
                Perimeter Security Solutions (UTM, IDS/IPS, Firewall, Proxy)
                <br />
                <br />
                Let TraceCorp guide and assist you in securing your business against evolving threats.
              </div>
            </div>
            <Button className="px-6 mt-10 w-full text-base font-semibold border-none leading-relaxed text-white rounded-lg bg-custom-blue min-h-[48px]">
              View all projects
            </Button>
          </div>
          <div className="flex flex-1 rounded-3xl bg-lime-400 bg-opacity-10 h-[760px] max-md:w-full">
            <img
              src="images/audit.svg"
              alt="Stock Cake"
              className="object-cover w-full h-[400px] xl:h-full rounded-3xl"
            />
          </div>
        </div>

        {/* Fourth Row */}
        <div className="flex flex-col-reverse xl:flex-row gap-8 pb-16 mt-16 w-full border-b border-solid">
          <div className="flex flex-1 rounded-3xl bg-lime-400 bg-opacity-10 h-[660px] max-md:w-full">
            <img
              src="images/Infrastructure.jpg"
              alt="Stock Cake"
              className="object-cover w-full h-[400px] xl:h-full rounded-3xl"
            />
          </div>
          <div className="flex flex-col justify-between px-12 py-10 bg-gray-100 rounded-3xl flex-1 min-w-[240px]">
            <div className="flex flex-col w-full">
              <h3 className="text-4xl font-bold leading-[54px] text-gray-800 max-md:text-2xl">
                I.T infrastructure and integration services
              </h3>
              <div className="mt-6 leading-7 text-gray-500">
                TraceCorp Solutions Technologies follows industry best practices and custom design to ensure your infrastructure is ready to support your expanding business needs. We start by defining and understanding your needs, assessing current capabilities, identifying gaps, and building a solid business case for change.
                <br />
                We offer a comprehensive range of services, from core network infrastructure (LAN, WAN) to advanced solutions (WLAN), including:
                <br />
                Network assessment and configuration
                <br />
                Backup and disaster recovery setup
                <br />
                Network security, detection, surveillance, CCTV, IP UVC, and access control
                <br />
                Structured cabling
                <br />
                <br />
                Our tailored approach ensures your network evolves to support your business efficiently and securely.
              </div>
            </div>
            <Button className="px-6 mt-10 w-full text-base font-semibold border-none leading-relaxed text-white rounded-lg bg-custom-blue min-h-[48px]">
              View all projects
            </Button>
          </div>
        </div>

        {/* Fifth Row */}
        <div className="flex flex-col-reverse xl:flex-row gap-8 pb-16 mt-16 w-full border-b border-solid">
          <div className="flex flex-col justify-between px-12 py-10 bg-gray-100 rounded-3xl flex-1 min-w-[240px]">
            <div className="flex flex-col w-full">
              <h3 className="text-4xl font-bold leading-relaxed text-gray-800 max-md:text-2xl">
                I.T service Management
              </h3>
              <div className="mt-6 leading-7 text-gray-500">
                Our IT service management stream is designed to enhance operations efficiency and customer service delivery through a balanced mix of people, processes, and technology. We focus on:
                <br />
                <br />
                Project Management
                <br />
                Performance and Asset Management
                <br />
                Business Continuity
                <br />
                <br />
                We also prepare training and user manuals for clients, providing reliable end-user support to ensure seamless business continuity.
              </div>
            </div>
            <Button className="px-6 mt-10 w-full text-base font-semibold border-none leading-relaxed text-white rounded-lg bg-custom-blue min-h-[48px]">
              View all projects
            </Button>
          </div>
          <div className="flex flex-1 rounded-3xl bg-lime-400 bg-opacity-10 h-[660px] max-md:w-full">
            <img
              src="images/ICT.jpg"
              alt="Stock Cake"
              className="object-cover w-full h-[400px] xl:h-full rounded-3xl"
            />
          </div>
        </div>

        {/* Sixth Row */}
        <div className="flex flex-col-reverse xl:flex-row gap-8 pb-16 mt-16 w-full border-b border-solid">
        <div className="flex flex-1 rounded-3xl bg-lime-400 bg-opacity-10 h-[710px] max-md:w-full">
            <img
              src="images/Process.jpg"
              alt="Stock Cake"
              className="object-cover w-full h-[400px] xl:h-full rounded-3xl"
            />
          </div>
          <div className="flex flex-col justify-between px-12 py-10 bg-gray-100 rounded-3xl flex-1 min-w-[240px]">
            <div className="flex flex-col w-full">
              <h3 className="text-4xl font-bold leading-[54px] text-gray-800 max-md:text-2xl">
                Utility Process Engineering and Development Solutions
              </h3>
              <div className="mt-6 leading-7 text-gray-500">
                Aligning business processes with the right technology is crucial for success. Our stream focuses on understanding clients' businesses, analyzing processes, and ensuring core processes are properly defined and integrated with technology.
                <br />
                <br />
                Key responsibilities include:
                <br />
                Defining and documenting all processes and sub-processes
                <br />
                Conducting process reengineering to meet customer objectives and strategic direction
                <br />
                Collaborating closely with the Applications Development Stream to ensure solutions align with client processes and adhere to industry best practices and standards
                <br />
                <br />
                Our approach ensures that our clients' business objectives are met efficiently and effectively.
              </div>
            </div>
            <Button className="px-6 mt-10 w-full text-base font-semibold border-none leading-relaxed text-white rounded-lg bg-custom-blue min-h-[48px]">
              View all projects
            </Button>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="flex overflow-hidden relative flex-col items-center px-36 py-20 mt-16 w-full leading-relaxed text-gray-50 bg-custom-blue max-md:px-5 max-md:mt-10">
        <h3 className="z-0 self-center text-4xl font-bold max-md:text-2xl">
          Ready to Transform your Business?
        </h3>
        <Button className="self-center px-16 text-white mt-8 text-base font-semibold rounded-xl border-white border-solid bg-custom-blue border-[1.5px] min-h-[48px] max-md:px-5">
          Get started
        </Button>
        <div className="flex absolute right-20 z-0 rounded-full aspect-square bg-gray-50 bg-opacity-20 bottom-[-250px] h-[374px] min-h-[374px] w-[374px] max-md:hidden" />
        <div className="flex absolute left-20 z-0 rounded-full aspect-square bg-gray-50 bg-opacity-20 bottom-[150px] h-[374px] min-h-[374px] w-[374px] max-md:hidden" />
      </div>
    </div>
  );
};

export default Services;
