import React, { useState } from 'react';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const testimonials = [
  {
    name: "John Mwangi",
    title: "Head of IT, Ongogi Town Water Utility",
    testimonial: `“Working with Tracecorp was a game-changer for our operations. Their Water Billing and Accounting System streamlined our entire billing process, reducing errors and increasing our revenue collection by 20%. Their expertise and support throughout the project were invaluable. I highly recommend Tracecorp for any utility company looking to modernize their systems.”`,
    imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/57a8632842c5b4d630d498d1e874791ed6f58a44d59d6345ad651499cb5b54d2?placeholderIfAbsent=true&apiKey=5bf51c3fc9cb49b480a07670cbcd768f",
  },
  {
    name: "Sarah Adebayo",
    title: "CFO, Lagos Water Corporation",
    testimonial: `“Tracecorp’s team exceeded our expectations by delivering a cutting-edge Water Billing and Accounting System. Their solution reduced our billing errors by 25% and significantly improved our financial reporting capabilities. Their professionalism and commitment to excellence are truly commendable.”`,
    imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/6ea3d494fbd2e300f5d102161093d8f4952f3e38e805939b88a4930d0d988ca0?placeholderIfAbsent=true&apiKey=5bf51c3fc9cb49b480a07670cbcd768f",
  },
  {
    name: "David Nkosi",
    title: "CEO, AquaLogix Solutions",
    testimonial: `“Our collaboration with Tracecorp was nothing short of exceptional. They understood our unique challenges and provided a tailored solution that optimized our logistics management. Their system enhanced our operational efficiency and contributed to a significant reduction in overhead costs.”`,
    imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/76b213ce26563bda7b437eb3bfd287792a2d95c4b2ec0a024e27d2390f7feb85?placeholderIfAbsent=true&apiKey=5bf51c3fc9cb49b480a07670cbcd768f",
  },
  {
    name: "Mary Okoro",
    title: "Project Manager, FinEdge Financial Services",
    testimonial: `“Tracecorp delivered a robust fintech solution that revolutionized our payment processing. Their attention to detail and customer-focused approach made the entire process smooth and successful. Our clients are happier, and our transaction processing is faster than ever before.”`,
    imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/9dc251cb8bb2dbb3728ce99d6a914b6deaf3480807f8b9d6cf3ce7fbb4763cc2?placeholderIfAbsent=true&apiKey=5bf51c3fc9cb49b480a07670cbcd768f",
  },
  {
    name: "Mary Okoro",
    title: "Project Manager, FinEdge Financial Services",
    testimonial: `“Tracecorp delivered a robust fintech solution that revolutionized our payment processing. Their attention to detail and customer-focused approach made the entire process smooth and successful. Our clients are happier, and our transaction processing is faster than ever before.”`,
    imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/9dc251cb8bb2dbb3728ce99d6a914b6deaf3480807f8b9d6cf3ce7fbb4763cc2?placeholderIfAbsent=true&apiKey=5bf51c3fc9cb49b480a07670cbcd768f",
  },
  {
    name: "David Nkosi",
    title: "CEO, AquaLogix Solutions",
    testimonial: `“Our collaboration with Tracecorp was nothing short of exceptional. They understood our unique challenges and provided a tailored solution that optimized our logistics management. Their system enhanced our operational efficiency and contributed to a significant reduction in overhead costs.”`,
    imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/76b213ce26563bda7b437eb3bfd287792a2d95c4b2ec0a024e27d2390f7feb85?placeholderIfAbsent=true&apiKey=5bf51c3fc9cb49b480a07670cbcd768f",
  },
  // Add more testimonials as needed
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 4; // Number of items to show at a time

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - itemsToShow : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - itemsToShow ? 0 : prevIndex + 1
    );
  };

  const visibleTestimonials = testimonials.slice(currentIndex, currentIndex + itemsToShow);

  return (
    <div className="pt-32 px-3 md:px-0 md:ml-36">
      <div className="flex flex-col">
        <div className="flex md:flex-row justify-between gap-10 mb-8">
          <h2 className="sm:text-4xl text-2xl font-bold text-gray-800">Client Testimonials</h2>
          <div className="flex space-x-4">
            <LeftOutlined
              onClick={handlePrev}
              className="cursor-pointer text-4xl text-gray-600 hover:text-gray-800"
            />
            <RightOutlined
              onClick={handleNext}
              className="cursor-pointer text-4xl text-gray-600 hover:text-gray-800"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {visibleTestimonials.map((testimonial, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row lg:flex-col gap-4 p-4 bg-lime-400 bg-opacity-30 rounded-2xl"
            >
             <div className='flex flex-col'>
             <img
                loading="lazy"
                srcSet={`${testimonial.imgSrc}&width=100 100w, ${testimonial.imgSrc}&width=200 200w, ${testimonial.imgSrc}&width=400 400w, ${testimonial.imgSrc}&width=800 800w`}
                src={testimonial.imgSrc}
                alt={testimonial.name}
                className="w-16 h-16 rounded-full object-cover"
              />
              <div className='md:mt-5 mt-2'>
                <h4 className="text-base font-semibold text-gray-700">
                  {testimonial.name}
                </h4>
                <div className="text-xs text-gray-500 mb-2">
                  {testimonial.title}
                </div>
              </div>
             </div>
              <div className="flex flex-col justify-center">
                <div className="text-base leading-7 text-gray-700">
                  {testimonial.testimonial}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
