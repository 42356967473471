import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import OurServices from "./OurServices";
import Images from "./Images";
import Carousel from "./Carousel";

function Home() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center mt-36 md:mt-14 bg-white">
      <div className="flex flex-col self-stretch mt-32 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-10 items-center justify-between px-36 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col self-stretch text-base my-auto w-full lg:w-[40%]">
            <div className="flex flex-col w-full max-md:max-w-full">
              <div className="px-6 font-semibold leading-relaxed rounded-3xl text-center bg-custom-light-green p-3 text-custom-blue max-md:px-5 max-md:max-w-full">
                Welcome to Tracecorp Solutions
              </div>
              <h1 className="mt-6 sm:text-4xl text-2xl font-bold text-gray-800 max-md:max-w-full">
                TraceCorp Solutions :
                Your Partner in Digital
                Transformation
              </h1>
              <div className="mt-6 leading-7 text-gray-500 max-md:max-w-full">
                We specialise in crafting innovative, cost-effective digital
                solutions that precisely align with your business goals. From
                inception, TraceCorp has grown into a leading ICT consulting
                firm, delivering comprehensive strategies to optimise operations
                and fuel growth in organisations
              </div>
              <div className="flex flex-col md:flex-row gap-4 mt-12">
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch px-8 sm:w-full">Schedule a Call</a>
                <a href="https://youtu.be/qCsxC0Wu1fE" target="_blank" className="text-custom-blue bg-white text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch border border-[1.5px] border-custom-blue px-8 sm:w-full">Watch Demo Video</a>
              </div>
            </div>
          </div>
          <Images />
        </div>

        <div className="flex flex-col lg:flex-row flex-wrap gap-10 items-center px-36 mt-14 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <h4 className="self-stretch my-auto text-xl font-semibold leading-relaxed text-gray-500">
            Partners
          </h4>
          <div className="flex flex-wrap flex-1 shrink gap-10 justify-between items-center self-stretch my-auto basis-0">
            <img
              loading="lazy"
              srcSet="../../../images/logo1.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[60px]"
            />
            <img
              loading="lazy"
              srcSet="../../../images/logo2.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-[1.08] w-[65px]"
            />
            <img
              loading="lazy"
              srcSet="../../../images/logo3.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[60px]"
            />
            <img
              loading="lazy"
              srcSet="../../../images/logo4.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[60px]"
            />
            <img
              loading="lazy"
              srcSet="../../../images/logo5.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[60px]"
            />
            <img
              loading="lazy"
              srcSet="../../../images/logo6.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[60px]"
            />
            <img
              loading="lazy"
              srcSet="../../../images/logo7.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[60px]"
            />
            <img
              loading="lazy"
              srcSet="../../../images/logo8.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[60px]"
            />
            <img
              loading="lazy"
              srcSet="../../../images/logo9.svg"
              className="object-contain shrink-0 self-stretch my-auto aspect-square w-[60px]"
            />
          </div>
        </div>
        <div className="flex flex-col justify-center py-12 mt-14 w-full leading-relaxed bg-gray-100 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col lg:flex-row flex-wrap gap-10 items-start px-36 w-full max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col flex-1 shrink pl-14 basis-14 min-w-[240px]">
              <h2 className="text-4xl font-bold text-gray-800">10+</h2>
              <div className="mt-4 text-base text-gray-500">
                years of experience
              </div>
            </div>
            <div className="flex flex-col flex-1 shrink pl-14 basis-0 min-w-[240px]">
              <h2 className="text-4xl font-bold text-gray-800">90%</h2>
              <div className="mt-4 text-base text-gray-500">
                client satisfaction rate
              </div>
            </div>
            <div className="flex flex-col flex-1 shrink pl-14 basis-0 min-w-[240px]">
              <h2 className="text-4xl font-bold text-gray-800">5+</h2>
              <div className="mt-4 text-base text-gray-500">
                countries worldwide
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-20 w-full max-md:mt-10 md:px-36 px-3 max-md:max-w-full">
        <div className="w-full leading-relaxed text-start">
          <h2 className="sm:text-4xl text-2xl font-bold text-gray-800 w-full">
            Our core Services
          </h2>
          <p className="mt-2 text-base text-gray-500 w-full">
            Empowering your business with solutions
          </p>
        </div>
        <OurServices />
      </div>

      <div className="flex flex-col px-36 mt-32 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-4 items-end w-full leading-relaxed max-md:max-w-full">
          <div className="flex flex-col flex-1 shrink basis-12 min-w-[240px] max-md:max-w-full">
            <h2 className="sm:text-4xl text-2xl font-bold text-gray-800 max-md:max-w-full">
              Featured Projects
            </h2>
            <div className="mt-2 text-base text-gray-500 max-md:max-w-full">
              Showcasing Our Expertise in Action
            </div>
          </div>
          <Button className="px-6 text-base font-semibold text-white border-none rounded-lg bg-custom-blue min-h-[40px] max-md:px-5">
            View all projects
          </Button>
        </div>
        <div className="flex flex-col lg:flex-row flex-wrap gap-8 items-stretch mt-6 w-full">
          <div className="flex flex-col w-full lg:w-[47.5%]">
            <div className="relative flex flex-col items-end px-4 pt-2 pb-48 w-full text-xs font-semibold leading-relaxed text-gray-50 rounded-2xl min-h-[300px] overflow-hidden sm:px-6 md:px-8 lg:px-16">
              <img
                loading="lazy"
                srcSet="../../../images/project1.svg"
                className="object-cover absolute inset-0 w-full h-full"
                alt="Project Image"
              />
            </div>
            <div className="flex flex-col mt-8 w-full">
              <h3 className="text-xl font-bold leading-relaxed text-gray-800 sm:text-2xl">
                Project: Upgrade of Lagos Water Corporation customer information, Billing and Collection
                System
              </h3>
              <div className="mt-4 leading-7 text-gray-500">
                TraceCorp Solutions conducted a comprehensive analysis of billing, customer
                relations, and revenue collection processes for LWC. As a result, they implemented
                process reengineering and integration to streamline operations.
                We developed a new web-based integrated billing and customer management
                system, seamlessly integrated with the LWC website. This allows customers to apply
                for water services and pay bills online, enhancing user experience and accessibility.
              </div>
              <Button className="px-4 mt-8 w-full text-base font-semibold leading-relaxed rounded-lg border-[1.5px] border-custom-blue min-h-[48px] text-custom-blue">
                Read case study
              </Button>
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-[47.5%]">
            <div className="relative flex flex-col items-end px-4 pt-2 pb-48 w-full text-xs font-semibold leading-relaxed text-gray-50 rounded-2xl min-h-[300px] overflow-hidden sm:px-6 md:px-8 lg:px-16">
              <img
                loading="lazy"
                srcSet="../../../images/project2.svg"
                className="object-cover absolute inset-0 w-full h-full"
                alt="Project Image"
              />
            </div>
            <div className="flex flex-col mt-8 w-full">
              <h3 className="text-xl font-bold leading-relaxed text-gray-800 sm:text-2xl">
                Project: Consultancy Services for design development and deployment
                of an Electronic Procurement System for Lagos Water
                Corporation
              </h3>
              <div className="mt-4 leading-7 text-gray-500">
                TraceCorp Solutions conducted a thorough analysis of LWC&#39;s procurement processes,
                focusing on process reengineering and integration. We designed and developed a
                new web-based integrated procurement system that digitizes and streamlines
                planning, budgeting, bidding, and sourcing. This system is integrated with the LWC website, allowing suppliers to easily apply for
                and submit bids online.
              </div>
              <Button className="px-4 mt-8 w-full text-base font-semibold leading-relaxed rounded-lg border-[1.5px] border-custom-blue min-h-[48px] text-custom-blue">
                Read case study
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* carousel */}
      <Carousel />
      {/* carousel ends here */}
      <div className="flex flex-col pt-20 leading-relaxed text-gray-50 max-md:pt-24 w-full">
        <div className="flex overflow-hidden relative flex-col items-center px-36 py-20 bg-custom-blue max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <h2 className="z-0 self-center text-center sm:text-4xl text-2xl font-bold max-md:max-w-full">
            Join our list of satisfied clients
          </h2>
          <Button
            onClick={() => navigate("/contact")}
            className="self-center text-white mt-8 text-base font-semibold rounded-xl border-white border-solid bg-custom-blue border-[1.5px] h-[48px]"
          >
            Contact us
          </Button>
          <div className="flex absolute right-20 z-0 rounded-full aspect-square bg-gray-50 bg-opacity-20 bottom-[-250px] h-[374px] min-h-[374px] w-[374px]" />
          <div className="flex absolute left-20 z-0 rounded-full aspect-square bg-gray-50 bg-opacity-20 bottom-[150px] h-[374px] min-h-[374px] w-[374px]" />
        </div>
      </div>
    </div>
  );
}

export default Home;
