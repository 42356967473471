import { Button } from "antd";
import * as React from "react";

const About = () => {
  const [activeSection, setActiveSection] = React.useState("mission");

  const content = {
    vision: {
      title: "Vision",
      text: "At TraceCorp Solutions, we're committed to revolutionising the utility industry in Africa. Our Vision is to be the continent's premier hub for cutting-edge solutions that set new benchmarks for technological excellence.",
      imageUrl: "../../images/vision.svg",
    },
    mission: {
      title: "Mission",
      text: "Empowering organisations, utilities and enterprises to excel by designing customised ICT solutions that comprise of the latest technology. We are dedicated to understanding and addressing our clients' unique needs at every stage of the journey.",
      imageUrl: "../../images/vision.svg",
    },
    story: {
      title: "Our story",
      text: "Over the years, we’ve grown from a small team with big dreams to a trusted partner for businesses across various industries. Our journey has been marked by key milestones, from the development of our first utility billing system to becoming a leader in ICT advisory services and training. Each step of our story is a testament to our unwavering commitment to innovation, quality, and customer success.",
      imageUrl: "../../images/vision.svg",
    },
    values: {
      title: "Our values",
      html: `
        <div class="flex flex-col w-full">
          <div class="flex flex-col">
            
            <div class="flex flex-col md:flex-row gap-8 md:gap-8">
              <div class="flex flex-col md:w-1/2 gap-8">
                <div class="flex items-start px-8 py-10 rounded-2xl bg-gray-100">
                  <h4 class="w-8 h-8 flex items-center justify-center text-lg font-bold text-white bg-custom-blue rounded-full">
                    1
                  </h4>
                  <div class="flex flex-col flex-1 ml-4">
                    <h3 class="text-lg md:text-2xl font-bold text-gray-600">
                      Innovation
                    </h3>
                    <div class="text-sm md:text-base text-gray-500 mt-2">
                      We believe in pushing boundaries and exploring new possibilities to deliver cutting-edge solutions that keep our clients ahead of the curve.
                    </div>
                  </div>
                </div>
                <div class="flex items-start px-8 py-10 rounded-2xl bg-gray-100">
                  <h4 class="w-8 h-8 flex items-center justify-center text-lg font-bold text-white bg-custom-blue rounded-full">
                    2
                  </h4>
                  <div class="flex flex-col flex-1 ml-4">
                    <h3 class="text-lg md:text-2xl font-bold text-gray-600">
                      Integrity
                    </h3>
                    <div class="text-sm md:text-base text-gray-500 mt-2">
                      Trust is the foundation of our relationships. We operate with transparency and honesty, ensuring that we always act in the best interests of our clients.
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col md:w-1/2 gap-8">
                <div class="flex items-start px-8 py-10 rounded-2xl bg-gray-100">
                  <h4 class="w-8 h-8 flex items-center justify-center text-lg font-bold text-white bg-custom-blue rounded-full">
                    3
                  </h4>
                  <div class="flex flex-col flex-1 ml-4">
                    <h3 class="text-lg md:text-2xl font-bold text-gray-600">
                      Customer Focus
                    </h3>
                    <div class="text-sm md:text-base text-gray-500 mt-2">
                      Our clients are at the heart of everything we do. We listen, we adapt, and we deliver solutions that are tailored to their specific needs.
                    </div>
                  </div>
                </div>
                <div class="flex items-start px-8 py-10 rounded-2xl bg-gray-100">
                  <h4 class="w-8 h-8 flex items-center justify-center text-lg font-bold text-white bg-custom-blue rounded-full">
                    4
                  </h4>
                  <div class="flex flex-col flex-1 ml-4">
                    <h3 class="text-lg md:text-2xl font-bold text-gray-600">
                      Excellence
                    </h3>
                    <div class="text-sm md:text-base text-gray-500 mt-2">
                      We strive for perfection in every aspect of our work, from the initial concept to the final delivery, ensuring the highest standards of quality.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `,
    },
  };

  return (
    <div>
      <div className="flex justify-center">
        <div className="flex flex-col md:px-8 lg:px-12 mt-36 md:mt-48 w-full max-w-screen-xl">
          
          {/* Header Section */}
          <div className="flex flex-col w-full bg-white">
            <div className="flex flex-col mb-10 w-full max-md:px-5 max-md:mt-10">
              <div className="flex flex-col py-16 px-6 w-full rounded-3xl bg-custom-light-green">
                <h4 className="px-6 py-2 self-center leading-relaxed rounded-3xl bg-custom-blue text-white max-md:px-5">
                  Who we are
                </h4>
                <h2 className="mt-6 text-4xl font-bold text-center text-gray-800 max-md:text-2xl">
                  Innovating with Purpose, Delivering with Integrity
                </h2>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full bg-white">
            <div className="flex px-6 md:px-0 flex-col w-full">
              <nav
                aria-label="About sections"
                className="flex flex-wrap gap-4 items-center mt-2 text-sm md:text-base leading-relaxed text-gray-800 border-b border-solid"
              >
                {Object.keys(content).map((key) => (
                  <h4
                    key={key}
                    onClick={() => setActiveSection(key)}
                    className={`flex-1 cursor-pointer text-left py-2 ${
                      activeSection === key
                        ? "font-bold border-b-2 border-gray-500"
                        : ""
                    }`}
                    role="tab"
                    aria-selected={activeSection === key}
                    aria-controls={`section-${key}`}
                  >
                    {content[key].title}
                  </h4>
                ))}
              </nav>
              <div className="flex flex-col md:flex-row gap-4 md:gap-8 items-start mt-16">
                <section
                  className={`flex flex-col px-5 py-8 rounded-3xl min-h-[280px] ${
                    activeSection === "values" ? "bg-white" : "bg-gray-100"
                  } w-full`}
                  id={`section-${activeSection}`}
                >
                  <h3 className="text-2xl md:text-3xl lg:text-4xl font-bold leading-relaxed text-gray-800">
                    {content[activeSection].title}
                  </h3>
                  {activeSection === "values" ? (
                    <div
                      className="mt-4 md:mt-6 text-sm md:text-base text-gray-500"
                      dangerouslySetInnerHTML={{
                        __html: content[activeSection].html,
                      }}
                    />
                  ) : (
                    <div className="mt-4 md:mt-6 text-gray-500">
                      {content[activeSection].text}
                    </div>
                  )}
                </section>
                {activeSection !== "values" && (
                  <div className="flex flex-col w-full max-w-[600px]">
                    <img
                      loading="lazy"
                      src={content[activeSection].imageUrl}
                      alt={content[activeSection].title}
                      className="object-cover w-full h-full rounded-3xl"
                    />
                  </div>
                )}
              </div>
              <div className="text-base leading-7 text-gray-500 md:p-10 mt-8 rounded-3xl mb-16 max-md:max-w-full bg-gray-100">
                TraceCorp Solutions Limited is a fully registered Private Liability
                Company operating under the Companies Act of Uganda. Founded in 2009 by
                two passionate IT experts, we began by developing mobile applications
                and billing solutions for various companies in Uganda.
                <br />
                <br />
                Since then, we have grown into a full-fledged ICT consulting firm with a
                global footprint. Our extensive team of ICT and management experts
                delivers innovative, tailored, and multifaceted solutions strategically
                designed to support our clients' operations and drive business success.
                <br />
                <br />
                We are at forefront of providing enterprise-level business process
                automation, Organisational Change Management, Institutional Development
                Services, and General Technical Assistance to both public and private
                sectors worldwide
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center bg-custom-blue md:flex-row z-0 flex-wrap gap-8 self-center py-20 max-md:max-w-full">
        <div className="flex flex-col min-w-[240px] w-[582px] md:px-0 px-4 max-md:max-w-full">
          <img
            loading="lazy"
            src="images/team.svg"
            className="object-contain flex-1 w-full rounded-3xl aspect-[1.25] max-md:max-w-full"
          />
        </div>
        <div className="flex flex-col px-12 pt-12 pb-8 my-auto text-base  border leading-relaxed bg-white rounded-3xl min-w-[240px] w-[582px] max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col w-full max-md:max-w-full">
            <h2 className="text-4xl font-bold text-gray-800 max-md:max-w-full">
              Why choose us?
            </h2>
            <div className="mt-6 leading-7 text-gray-500 max-md:max-w-full">
              At TraceCorp Solutions, we promise to bring creativity, agility,
              flexibility, passion, and competence to every project. Your
              satisfaction is our top priority. <br />
              <br />
              We commit to deeply understanding your needs and delivering
              innovative, reliable, and secure solutions that are both
              fit-for-purpose and cost-effective.
              <br />
              <br />
              Because to us, you are the priority.
            </div>
            <Button className="self-start px-16 mt-6 font-semibold text-white rounded-xl border-none bg-custom-blue min-h-[48px] max-md:px-5">
              Get started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
