import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Services from './pages/Services';
import Header from './components/Header';
import About from './pages/About';
import Footer from './components/footer';
import ContactUs from './pages/ContactUs';
import Product from './pages/Product';


function App() {
  return (
    <Router>
      <Header />
      <div>
        <Routes>
          <Route path="/services" element={<Services />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/products" element={<Product />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

// Test 123
export default App;
