import * as React from "react";

function OurServices() {
  const servicesRow1 = [
    {
      imgSrc: "../../../images/utility_icon.svg",
      alt: "Utility Management Systems",
      text: "Utility Management Systems",
    },
    {
      imgSrc: "../../../images/payment_icon.svg",
      alt: "Payment Platforms",
      text: "Payment Platforms",
    },
    {
      imgSrc: "../../../images/mobile_icon.svg",
      alt: "Mobile Apps",
      text: "Mobile Apps",
    },
  ];

  const servicesRow2 = [
    {
      imgSrc: "../../../images/computer_icon.svg",
      alt: "Desktop Applications",
      text: "Desktop Applications",
    },
    {
      imgSrc: "../../../images/web_icon.svg",
      alt: "Web-Based Applications",
      text: "Web-Based Applications",
    },
    {
      imgSrc: "../../../images/ussd_icon.svg",
      alt: "USSD Applications",
      text: "USSD Applications",
    },
  ];

  return (
    <div className="flex flex-col pt-4 w-full">
      <div className="flex flex-col gap-8 mt-10">
        {/* First row of cards */}
        <div className="flex flex-col lg:flex-row gap-6">
          {servicesRow1.map((service, index) => (
            <div
              key={index}
              className="relative flex flex-col justify-center p-6 lg:w-[32%] bg-white rounded-2xl border border-gray-200 transition-shadow duration-300 ease-in-out overflow-hidden"
            >
              {/* Decorative image at the top-right corner */}
              <img
                loading="lazy"
                src="/images/Ellipse.svg"
                className="absolute top-0 right-0 w-48 h-48"
                alt="decorative"
                style={{ clipPath: "circle(50% at 100% 0)" }}
              />
              <div className="flex justify-center items-center w-12 h-12 bg-gray-100 rounded-full mb-4">
                <img
                  loading="lazy"
                  src={service.imgSrc}
                  className="object-contain w-8 h-8"
                  alt={service.alt}
                />
              </div>
              <h4 className="text-lg font-semibold text-gray-700">
                {service.text}
              </h4>
            </div>
          ))}
        </div>

        {/* Second row of cards */}
        <div className="flex flex-col lg:flex-row gap-6">
          {servicesRow2.map((service, index) => (
            <div
              key={index}
              className="relative flex flex-col justify-center p-6 lg:w-[32%] bg-white rounded-2xl border border-gray-200 transition-shadow duration-300 ease-in-out overflow-hidden"
            >
              {/* Decorative image at the top-right corner */}
              <img
                loading="lazy"
                src="/images/Ellipse.svg"
                className="absolute top-0 right-0 w-48 h-48"
                alt="decorative"
                style={{ clipPath: "circle(50% at 100% 0)" }}
              />
              <div className="flex justify-center items-center w-12 h-12 bg-gray-100 rounded-full mb-4">
                <img
                  loading="lazy"
                  src={service.imgSrc}
                  className="object-contain w-8 h-8"
                  alt={service.alt}
                />
              </div>
              <h4 className="text-lg font-semibold text-gray-700">
                {service.text}
              </h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurServices;
