import React, { useState, useEffect } from 'react';

const images = [
    '../../../images/carousel1.svg',
    '../../../images/carousel2.svg',
    '../../../images/carousel3.svg',
];

// const texts = [
//     'Consulting Services',
//     <>Training <br /> Services</>, // Use JSX to include line break
//     'Application Development',
// ];

const Images = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative w-full lg:w-[50%] overflow-hidden rounded-xl">
            <div
                className="flex transition-transform duration-800 ease-in-out"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((src, index) => (
                    <div
                        key={index}
                        className="relative flex-shrink-0 w-full bg-gray-200 flex items-center justify-center overflow-hidden"
                        style={{ height: '500px' }} // Adjust height as needed
                    >
                        <img
                            src={src}
                            alt={`Slide ${index + 1}`}
                            className="w-full h-full object-cover" // Ensure the image covers the container
                        />
                        {/* <div
                            className="absolute rounded-full bg-blue-800 text-white text-center text-lg p-2 flex items-center justify-center"
                            style={{
                                width: '120px',  // Reduced size
                                height: '120px', // Reduced size
                                top: '10px',     // Adjust position as needed
                                left: '10px',    // Adjust position as needed
                                fontSize: '14px', // Reduced font size
                                overflow: 'hidden',
                            }}
                        >
                            <div style={{ transform: 'translateY(20%)' }}>
                                {texts[index]}
                            </div>
                        </div> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Images;
