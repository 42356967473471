import * as React from "react";
import { Button } from "antd";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = (path) => location.pathname === path;

  const closeMenu = () => setIsOpen(false);

  return (
    <header className="relative">
      {/* Desktop Navigation */}
      <div className="hidden md:flex md:flex-wrap gap-10 justify-between items-center self-stretch px-36 py-6 text-base leading-relaxed border-b border-solid max-md:px-5 fixed top-0 left-0 right-0 bg-white z-50">
        <div className="flex flex-wrap gap-8 items-center self-stretch my-auto text-gray-500 min-w-[240px] max-md:max-w-full">
          <div className="gap-8 self-stretch my-auto text-xl font-bold text-gray-800 whitespace-nowrap">
            <img src="../../images/logo.svg" className="h-30 w-30" />
          </div>
          <Link
            to="/"
            className={`self-stretch my-auto ${
              isActive("/") ? "text-black font-bold" : "text-gray-500"
            }`}
          >
            Home
          </Link>
          <Link
            to="/services"
            className={`self-stretch my-auto ${
              isActive("/services") ? "text-black font-bold" : "text-gray-500"
            }`}
          >
            What we do
          </Link>
          <Link
            to="/about-us"
            className={`self-stretch my-auto ${
              isActive("/about-us") ? "text-black font-bold" : "text-gray-500"
            }`}
          >
            Who we are
          </Link>
          <Link
            to="/products"
            className={`self-stretch my-auto ${
              isActive("/products") ? "text-black font-bold" : "text-gray-500"
            }`}
          >
            Our products
          </Link>
        </div>
        <Button
          onClick={() => navigate("/contact")}
          className="self-stretch px-6 my-auto font-semibold rounded-lg border-none min-h-[40px] text-white bg-custom-blue max-md:px-5"
        >
          Contact us
        </Button>
      </div>

      {/* Mobile Navigation */}
      <div className="flex md:hidden justify-between items-center px-5 py-6 border-b border-solid fixed top-0 left-0 right-0 bg-white z-50">
        <div className="text-xl font-bold text-gray-800">
          {" "}
          <img src="/images/logo.svg" className="h-30 w-30" />
        </div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-2 rounded-md text-gray-800"
        >
          {isOpen ? (
            <XMarkIcon className="w-6 h-6" />
          ) : (
            <Bars3Icon className="w-6 h-6" />
          )}
        </button>
      </div>

      {/* Mobile Menu Dialog */}
      <Dialog
        open={isOpen}
        onClose={closeMenu}
        className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-80"
      >
        <Dialog.Panel className="relative bg-white w-full max-w-sm mx-auto mt-16 p-6">
          <button
            onClick={closeMenu}
            className="absolute top-4 right-4 p-2 text-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
          <div className="flex flex-col gap-6 text-gray-800">
            <Link
              to="/"
              className={`text-xl font-semibold ${
                isActive("/") ? "text-black font-bold" : "text-gray-800"
              }`}
              onClick={closeMenu}
            >
              Home
            </Link>
            <Link
              to="/services"
              className={`text-xl font-semibold ${
                isActive("/services") ? "text-black font-bold" : "text-gray-800"
              }`}
              onClick={closeMenu}
            >
              What we do
            </Link>
            <Link
              to="/about-us"
              className={`text-xl font-semibold ${
                isActive("/about-us") ? "text-black font-bold" : "text-gray-800"
              }`}
              onClick={closeMenu}
            >
              Who we are
            </Link>
            <Link
              to="/products"
              className={`text-xl font-semibold ${
                isActive("/products") ? "text-black font-bold" : "text-gray-800"
              }`}
              onClick={closeMenu}
            >
              Our products
            </Link>
            <Button
              onClick={() => {
                navigate("/contact");
                closeMenu();
              }}
              className="w-full font-semibold rounded-lg border-solid border-[1.5px] border-slate-500 text-slate-500"
              aria-label="Navigate to contact page"
            >
              Contact us
            </Button>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Header;
