import React from "react";
import "./ContactUs.css";

const ContactUs = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());
    console.log("Form values:", values);
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@tracecorpsolutions.com";
  };

  const handleMapClick = () => {
    window.open("https://www.google.com/maps?q=Plot+32+Lumumba+Avenue+PO+Box:+24919", "_blank");
  };

  const handlePhoneClick = () => {
    navigator.clipboard.writeText("+256 756770277").then(() => {
      alert("Phone number copied to clipboard!");
    }).catch((error) => {
      console.error("Failed to copy phone number:", error);
    });
  };

  return (
    <div className="flex flex-col relative px-4 sm:px-6 md:px-12 lg:px-36 bg-white">
      <div className="mt-36 md:mt-48">
        <section
          className="flex flex-col w-full"
          style={{
            backgroundImage: "url(../../../images/map.svg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "60vh",
            position: "relative",
            marginTop: "-10vh",
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="flex flex-col relative left-20 md:left-36 items-center mb-5">
            {/* <div className="map-pin">
              <div className="pin-tail"></div>
              <div className="pin-dot"></div>
            </div> */}
            {/* <div
              className="mt-4 px-4 py-2 text-white bg-custom-blue rounded-lg cursor-pointer"
              onClick={handleMapClick}
              style={{ cursor: 'pointer' }} // Indicate clickable element
            >
              View on the Map
            </div> */}
          </div>
          <header className="flex flex-col items-center w-full text-center">
            <h4 className="px-6 py-2 font-semibold rounded-3xl bg-custom-light-green text-custom-blue">
              We love to hear from you
            </h4>
            <h1 className="mt-6 sm:text-4xl text-2xl font-bold text-gray-800">
              Get in touch with our team
            </h1>
          </header>
        </section>
        <div className="flex flex-wrap gap-4 items-center">
          <div className="flex overflow-hidden flex-col flex-1 shrink self-stretch px-8 py-6 my-auto rounded-2xl border border border-solid basis-0 min-h-[268px] min-w-[240px] max-md:px-5">
            <div className="flex gap-2.5 justify-center items-center w-12 h-12 bg-gray-100 rounded-3xl min-h-[48px]">
              <img
                loading="lazy"
                src="images/mail.svg"
                className="object-contain self-stretch my-auto w-6 aspect-square"
              />
            </div>
            <h4 className="mt-4 text-xl font-semibold leading-relaxed text-gray-800">
              E-mail us
            </h4>
            <div className="flex-1 mt-4 text-base leading-7 text-gray-500">
              Send us an email and we shall respond with one business day
            </div>
            <div
              className="px-6 mt-4 w-full text-base font-semibold leading-relaxed whitespace-nowrap rounded-xl border-solid border-[1.5px] border-custom-blue py-2 text-center text-custom-blue max-md:px-5"
              onClick={handleEmailClick}
              style={{ cursor: 'pointer' }} // Indicate clickable element
            >
              info@tracecorpsolutions.com
            </div>
          </div>
          <div className="flex overflow-hidden flex-col flex-1 shrink self-stretch px-8 py-6 rounded-2xl border border-solid basis-0 min-w-[240px] max-md:px-5">
            <div className="flex gap-2.5 justify-center items-center w-12 h-12 bg-gray-100 rounded-3xl min-h-[48px]">
              <img
                loading="lazy"
                src="images/location.svg"
                className="object-contain self-stretch my-auto w-6 aspect-square"
              />
            </div>
            <h4 className="mt-4 text-xl font-semibold leading-relaxed text-gray-800">
              Visit us
            </h4>
            <div className="flex-1 mt-4 text-base leading-7 text-gray-500">
              Plot 32 Lumumba Avenue PO Box: 24919
            </div>
            <div
              className="px-6 mt-4 w-full text-base font-semibold leading-relaxed rounded-xl border-solid border-[1.5px] border-custom-blue py-2 text-center text-custom-blue max-md:px-5"
              onClick={handleMapClick}
              style={{ cursor: 'pointer' }} // Indicate clickable element
            >
              View on Google Maps
            </div>
          </div>
          <div className="flex overflow-hidden flex-col flex-1 shrink self-stretch px-8 py-6 my-auto rounded-2xl border border border-solid basis-0 min-h-[268px] min-w-[240px] max-md:px-5">
            <div className="flex gap-2.5 justify-center items-center w-12 h-12 bg-gray-100 rounded-3xl min-h-[48px]">
              <img
                loading="lazy"
                src="images/phone.svg"
                className="object-contain self-stretch my-auto w-6 aspect-square"
              />
            </div>
            <h4 className="mt-4 text-xl font-semibold leading-relaxed text-gray-800">
              Call us
            </h4>
            <div className="flex-1 mt-4 text-base leading-7 text-gray-500">
              Contact us and we shall attend to you immediately
            </div>
            <div
              className="px-6 mt-4 w-full text-base font-semibold leading-relaxed rounded-xl border-solid border-[1.5px] border-custom-blue py-2 text-center text-custom-blue max-md:px-5"
              onClick={handlePhoneClick}
              style={{ cursor: 'pointer' }} // Indicate clickable element
            >
              Tel: +256 756770277
            </div>
          </div>
        </div>
        <section className="mt-20 flex flex-col gap-10 md:flex-row md:justify-between md:gap-12">
          <header className="flex flex-col sm:text-4xl text-2xl font-bold w-full md:w-1/2">
            <h2 className="font-bold text-gray-800">
              Looking to Partner with Us?
            </h2>
            <h2 className="font-bold mt-6 text-custom-blue">
              Let us discuss your project!
            </h2>
          </header>

          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-6 w-full md:w-1/2"
          >
            <div className="flex flex-col gap-2">
              <label htmlFor="fullName" className="font-bold text-gray-800">
                Full name
              </label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder="Please enter a full name"
                className="border border-gray-300 rounded-lg p-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="email" className="font-bold text-gray-800">
                Email address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Please enter your email"
                className="border border-gray-300 rounded-lg p-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="phone" className="font-bold text-gray-800">
                Phone number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="Please enter your phone number"
                className="border border-gray-300 rounded-lg p-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="message" className="font-bold text-gray-800">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                placeholder="Description"
                className="border border-gray-300 rounded-lg p-2 w-full"
                required
              />
            </div>
            <button
              type="submit"
              className="px-6 mt-8 py-2 font-semibold rounded-lg bg-custom-blue text-white w-full"
            >
              Contact me
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default ContactUs;
