import * as React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Product = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col bg-white mt-36">
      <div className="flex flex-col w-full bg-white max-md:max-w-full">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="flex flex-col px-36 mt-12 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col p-16 w-full rounded-3xl bg-custom-light-green max-md:px-5 max-md:max-w-full">
              <div className="flex flex-col w-full leading-relaxed max-md:max-w-full">
                <h4 className="px-6 py-2 self-center leading-relaxed rounded-3xl bg-custom-blue text-white max-md:px-5">
                  Our products
                </h4>
                <h2 className="mt-4 sm:text-4xl text-2xl font-bold text-center text-gray-800 max-md:max-w-full">
                  Innovative Solutions for your Business Needs
                </h2>
              </div>
              <div className="self-center mt-4 text-base leading-7 text-center text-gray-500 w-full">
                Our ERP suite is designed to streamline operations, enhance
                efficiency, and provide you with the tools needed to drive
                success. Explore our flagship products below.
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:px-36">
          <div className="flex flex-col justify-center mt-16 w-full max-w-[1214px] max-md:px-5 max-md:mt-10">
            <div className="flex flex-col flex-wrap gap-8 lg:flex-row pb-16 border-b border-solid">
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    TraceBill
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    TraceBill streamlines the billing processes for utility companies, particularly in water services from the initial connection request to bill generation and payment.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    TraceAccounting
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    TraceAccounting enables organizations to record, manage, and report their financial transactions. It supports accurate financial reporting, ensures regulatory compliance, and aids in strategic planning.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
            </div>
            <div className="flex flex-col flex-wrap gap-8 lg:flex-row pb-16 mt-16 border-b border-solid">
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    TraceCRM
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    TraceCRM helps clients improve operations by managing customer interactions through features like contact management, scheduling, and notifications.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    TraceHRMS
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    TraceHRMS offers features such as employee data management, time and attendance, training and development, among others.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
            </div>
            <div className="flex flex-col flex-wrap gap-8 lg:flex-row pb-16 mt-16 border-b border-solid">
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    Asset Management System
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    The AMS manages, and optimizes asset utilization throughout their lifecycle, helping organizations maintain assets for maximum value.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    Fleet Management System
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    FMS helps to manage and streamline all aspects of a company's vehicle fleet operations
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
            </div>
            <div className="flex flex-col flex-wrap gap-8 lg:flex-row pb-16 mt-16 border-b border-solid">
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    Geographical Information System
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    The GIS allows users to visualize, question, analyze, and interpret spatial and geographic data to understand relationships, patterns, and trends.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    Water Quality Management System
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    The Water Quality Management System helps to monitor, treat, and manage water to ensure it meets safety and quality standards.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
            </div>
            <div className="flex flex-col flex-wrap gap-8 lg:flex-row pb-16 mt-16 border-b border-solid">
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    Performance Management System
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    The PMS enhance organizational performance by improving the performance of individuals and teams through a continuous cycle of goal setting and performance evaluation.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    Inventory Management System
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    The Inventory Management System (IMS) helps businesses track and manage their inventory efficiently.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
            </div>
            <div className="flex flex-col flex-wrap gap-8 lg:flex-row pb-16 mt-16 border-b border-solid">
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    TracePay
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    Trace-pay is a financial system that helps business to collect and payout to their employees/beneficiaries through mobile money and bank accounts.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
              <div className="flex flex-col flex-1 shrink justify-between px-12 py-10 my-auto text-base leading-relaxed bg-gray-100 rounded-3xl basis-0 min-h-[400px] min-w-[240px] max-md:px-5 max-md:max-w-full">
                <div className="flex flex-col w-full text-gray-800 max-md:max-w-full">
                  <h2 className="sm:text-4xl text-2xl font-bold max-md:max-w-full">
                    TraceSCM
                  </h2>
                  <div className="mt-4 leading-7 text-gray-500 max-md:max-w-full">
                    TraceSCM is the hallmark of automation for end-to-end supply chain management. From planning, budgeting, Requisitioning, Solicitation, Evaluation, issue of Purchase Order, Logistics and transport, stores and inventory, to workflow automation.
                  </div>
                </div>
                <a href="https://cal.com/tracecorp-solutions/30min" target="_blank" className="text-white bg-custom-blue mt-[24px] text-center h-[48px] rounded-xl font-semibold flex items-center justify-center self-stretch">Book a Demo</a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex overflow-hidden relative flex-col items-start px-36 py-20 mt-16 w-full text-base leading-relaxed text-white bg-custom-blue max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <h2 className="z-0 self-stretch text-4xl font-bold max-md:max-w-full">
            Ready to Transform your Business?
          </h2>
          <div className="z-0 self-stretch mt-8 leading-7 max-md:max-w-full">
            Our ERP suite is designed for interoperability. All our systems work
            together seamlessly, providing you with a unified platform to manage
            your business operations. Whether you need to integrate with
            existing tools or scale your operations, our products offer the
            flexibility and connectivity you need.
          </div>
          <Button
            onClick={() => navigate("/contact")}
            className="px-16 mt-8 font-semibold rounded-xl text-white border-gray-50 border-solid bg-custom-blue border-[1.5px] min-h-[48px] max-md:px-5">
            Contact Us
          </Button>
          <div className="flex absolute -bottom-60 z-0 rounded-full aspect-square bg-gray-50 bg-opacity-20 h-[374px] min-h-[374px] right-[290px] w-[374px]" />
          <div className="flex absolute right-0 z-0 max-w-full rounded-full bg-gray-50 bg-opacity-20 bottom-[150px] h-[374px] min-h-[374px] w-[374px]" />
        </div>
      </div>
    </div>
  );
};

export default Product;
